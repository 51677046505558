
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import NewsTeaserHome from './NewsTeaserHome.vue';

export default defineComponent({
  components: {
    NewsTeaserHome,
  },
  setup() {
    const store = useStore();

    store.dispatch('newsTeaserModule/checkPointsRedeemed');

    const redeemedPointsStatus = computed(
      () => store.state.newsTeaserModule.redeemedPointsStatus
    );

    const onRedeemPoints = () => {
      store.dispatch('newsTeaserModule/triggerRedeemPoints');
    };
    return {
      redeemedPointsStatus,
      onRedeemPoints,
    };
  },
});
