
import { defineComponent, reactive, ref, Ref } from 'vue';

import useBreakpoints from '@/use/useBreakpoints';
import { ImageWithSources } from '@/types';

import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Button from '@/components/Button/Button.vue';
import Modal from '@/components/Modal/Modal.vue';
import Hero from '@/components/Hero/Hero.vue';
import Showcase from '@/components/Showcase/Showcase.vue';
import TeaserCard from '../../components/TeaserCard/TeaserCard.vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';

import { VideoTeaser } from '../../types';

import blueImage1 from '../../assets/images/image-blue.png';
import blueImage2 from '../../assets/images/image-blue@2x.png';
import blueImage3 from '../../assets/images/image-blue@3x.png';

import blueShortImage1 from '../../assets/images/image-blue-short.png';
import blueShortImage2 from '../../assets/images/image-blue-short@2x.png';
import blueShortImage3 from '../../assets/images/image-blue-short@3x.png';

import comingSoon1 from '../../assets/images/image-coming-soon.png';
import comingSoon2 from '../../assets/images/image-coming-soon@2x.png';
import comingSoon3 from '../../assets/images/image-coming-soon@3x.png';

import redImage1 from '../../assets/images/image-red.png';
import redImage2 from '../../assets/images/image-red@2x.png';
import redImage3 from '../../assets/images/image-red@3x.png';

import noResidue1 from '../../assets/icons/icon-no-residue-black.png';
import noResidue2 from '../../assets/icons/icon-no-residue-black@2x.png';
import noResidue3 from '../../assets/icons/icon-no-residue-black@3x.png';

import noCleaning1 from '../../assets/icons/icon-no-cleaning-black.png';
import noCleaning2 from '../../assets/icons/icon-no-cleaning-black@2x.png';
import noCleaning3 from '../../assets/icons/icon-no-cleaning-black@3x.png';

import bladeless1 from '../../assets/icons/icon-bladeless-black.png';
import bladeless2 from '../../assets/icons/icon-bladeless-black@2x.png';
import bladeless3 from '../../assets/icons/icon-bladeless-black@3x.png';

import taste1 from '../../assets/icons/icon-taste-black.png';
import taste2 from '../../assets/icons/icon-taste-black@2x.png';
import taste3 from '../../assets/icons/icon-taste-black@3x.png';

import tabakstick1 from '../../assets/icons/icon-tabakstick-black.png';
import tabakstick2 from '../../assets/icons/icon-tabakstick-black@2x.png';
import tabakstick3 from '../../assets/icons/icon-tabakstick-black@3x.png';

import smoke1 from '../../assets/icons/icon-smoke-black.png';
import smoke2 from '../../assets/icons/icon-smoke-black@2x.png';
import smoke3 from '../../assets/icons/icon-smoke-black@3x.png';

export default defineComponent({
  components: {
    ResponsiveImage,
    Button,
    Icon,
    Showcase,
    TeaserCard,
    Modal,
    Hero,
  },
  props: {
    redeemedPointsStatus: { type: Object, default: false },
    onRedeemPoints: { type: Function, defaul: () => null },
  },
  setup() {
    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const selectedVideo: Ref<VideoTeaser | null> = ref(null);

    const noResidueImage: ImageWithSources = {
      alt: 'No Residue',
      mobile: {
        x1: noResidue1,
        x2: noResidue2,
        x3: noResidue3,
      },
      desktop: {
        x1: noResidue1,
        x2: noResidue2,
        x3: noResidue3,
      },
    };

    const noCleaningImage: ImageWithSources = {
      alt: 'No Cleaning',
      mobile: {
        x1: noCleaning1,
        x2: noCleaning2,
        x3: noCleaning3,
      },
      desktop: {
        x1: noCleaning1,
        x2: noCleaning2,
        x3: noCleaning3,
      },
    };

    const bladelessImage: ImageWithSources = {
      alt: 'Bladeless',
      mobile: {
        x1: bladeless1,
        x2: bladeless2,
        x3: bladeless3,
      },
      desktop: {
        x1: bladeless1,
        x2: bladeless2,
        x3: bladeless3,
      },
    };

    const tasteImage: ImageWithSources = {
      alt: 'Taste',
      mobile: {
        x1: taste1,
        x2: taste2,
        x3: taste3,
      },
      desktop: {
        x1: taste1,
        x2: taste2,
        x3: taste3,
      },
    };

    const tabakstickImage: ImageWithSources = {
      alt: 'Tabakstick',
      mobile: {
        x1: tabakstick1,
        x2: tabakstick2,
        x3: tabakstick3,
      },
      desktop: {
        x1: tabakstick1,
        x2: tabakstick2,
        x3: tabakstick3,
      },
    };

    const smokeImage: ImageWithSources = {
      alt: 'Smoke',
      mobile: {
        x1: smoke1,
        x2: smoke2,
        x3: smoke3,
      },
      desktop: {
        x1: smoke1,
        x2: smoke2,
        x3: smoke3,
      },
    };

    const blueImage: ImageWithSources = {
      alt: 'Teaser',
      mobile: {
        x1: blueImage1,
        x2: blueImage2,
        x3: blueImage3,
      },
      desktop: {
        x1: blueImage1,
        x2: blueImage2,
        x3: blueImage3,
      },
    };

    const blueShortImage: ImageWithSources = {
      alt: 'Teaser',
      mobile: {
        x1: blueShortImage1,
        x2: blueShortImage2,
        x3: blueShortImage3,
      },
      desktop: {
        x1: blueShortImage1,
        x2: blueShortImage2,
        x3: blueShortImage3,
      },
    };

    const comingSoonImage: ImageWithSources = {
      alt: 'Coming Soon',
      mobile: {
        x1: comingSoon1,
        x2: comingSoon2,
        x3: comingSoon3,
      },
      desktop: {
        x1: comingSoon1,
        x2: comingSoon2,
        x3: comingSoon3,
      },
    };

    const redImage: ImageWithSources = {
      alt: 'Teaser',
      mobile: {
        x1: redImage1,
        x2: redImage2,
        x3: redImage3,
      },
      desktop: {
        x1: redImage1,
        x2: redImage2,
        x3: redImage3,
      },
    };

    const displayedVideo = {
      image: blueShortImage,
      url: !isScreenLg
        ? 'https://pmg-dce-shop-cms-prod-assets.s3.eu-central-1.amazonaws.com/media/MOBILE_B385932_PT1_IQOS4_ILUMA_Teaser_Rules_Reinvented_LAU_MULTI_ATL_GIF_185_A_1080x1920_de-DE_25_AE004.mp4'
        : 'https://pmg-dce-shop-cms-prod-assets.s3.amazonaws.com/media/DESKTOP_B385931_PT1_IQOS4_ILUMA_Teaser_Rules_Reinvented_LAU_MULTI_ATL_GIF_185_A_1920x1080_de-DE_25_AE003.mp4',
    };

    const displayVideoModal = (videoTeaser: VideoTeaser) => {
      selectedVideo.value = videoTeaser;
    };

    const handleModalClose = () => {
      selectedVideo.value = null;
    };

    return {
      isScreenLg,
      ICON_THEMES,
      displayedVideo,
      selectedVideo,
      displayVideoModal,
      handleModalClose,
      //images
      blueImage,
      blueShortImage,
      comingSoonImage,
      redImage,
      //icons
      noResidueImage,
      noCleaningImage,
      bladelessImage,
      tasteImage,
      tabakstickImage,
      smokeImage,
    };
  },
});
